<!-- 交易公告详情页 -->
<template>
	<div>
		<div class="noticdd" v-if="false">
			<!-- 图片 -->
			<div class="not-left">
				<div class="not-lft-img">
					<img :src="imgSrc(delObj.thumb)" alt="">
				</div>
				<div class="not-arr">
					<div class="not-arr-item" @click="clkScoLft(1)">
						<i class="el-icon-arrow-left"></i>
					</div>
					<div class="img" id="lfImg">
						<img :src="imgSrc(delObj.thumb)" alt="" />
					</div>
					<div class="not-arr-item" @click="clkScoLft(2)">
						<i class="el-icon-arrow-right"></i>
					</div>
				</div>
			</div>
			<!-- 内容 -->
			<div class="not-right">
				<div class="not-right-top">
					<div>
						<div class="title">{{delObj.title}}</div>
						<div class="no">项目编号：{{delObj.projectno}}</div>
						<div class="baom">
							<ul class="bm" :class="!timeFlag(delObj.enddate)?'jzbm':''">
								<template v-if="timeFlag(delObj.enddate)">
									<li>正在</li>
									<li>报名</li>
								</template>
								<template v-else>
									<li>截止</li>
									<li>报名</li>
								</template>
							</ul>
							<div class="time" :class="!timeFlag(delObj.enddate)?'jztime':''">
								<template v-if="timeFlag(delObj.enddate)">
									倒计时{{count}}
								</template>
								<template v-else>
									报名截止
								</template>
							</div>
						</div>
						<div class="gpjg">
							挂牌价格<span class="price"> {{delObj.price}} </span>万元
							&nbsp;&nbsp;&nbsp;
							保证金<span class="price"> {{delObj.baozhengjprice}} </span>元
						</div>
						<div class="city">所属地区</div>
					</div>
					<div class="btnbm ejy_huiyuan_bm" id="12GQ20160021" v-show="timeFlag(delObj.enddate)">报名</div>
					<div class="btnbm jzbm" v-show="!timeFlag(delObj.enddate)">报名</div>
					
				</div>
				<div class="not-right-bom">
					<div>
						<span>公告发布日期</span>
						<span>{{timedd(delObj.inputtime)}}</span>
					</div>
					<div class="end-time">
						<span>公告结束日期</span>
						<span>{{delObj.enddate}}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- 图片 -->
		<div class="lcimg" v-if="false">
			<img src="http://61.178.68.41:8090/assets/img/%E9%A1%B9%E7%9B%AE%E6%B5%81%E7%A8%8B.73e4deda.png" alt="">
		</div>
		<div>
			<!-- <tap :taps="['挂牌信息','成交公告']" :tapIndex="tapIndex" :isMore="false" @clkTap="clkTap"></tap> -->
			<div v-show="tapIndex==0">
				<div style="font-size: 16px;" v-html="delObj.content" v-show="delObj.content"></div>
				<el-empty description="描述文字" v-show="!delObj.content"></el-empty>
			</div>
			<div v-show="tapIndex==1">
				<el-empty description="暂无数据"></el-empty>
			</div>
		</div>
		
		<div class="tip">如果您对以上项目有意向，请在下方留下您的联系方式：</div>
		<div class="from">
			<el-form label-width="40px" :model="ruleForm">
				<el-form-item label="主题">
					<el-input v-model="ruleForm.title"></el-input>
				</el-form-item>
				<!-- <el-form-item label="栏目">
					<el-select v-model="ruleForm.column" class="column">
						<el-option label="推介公告" value="1"></el-option>
						<el-option label="公告" value="2"></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item label="姓名">
					<el-input v-model="ruleForm.name" placeholder="请输入您的姓名"></el-input>
				</el-form-item>
				<el-form-item label="电话">
					<el-input v-model="ruleForm.mobile" placeholder="请输入您的电话"></el-input>
				</el-form-item>
				<el-form-item label="邮箱">
					<el-input v-model="ruleForm.email" placeholder="请输入您的邮箱"></el-input>
				</el-form-item>
				<el-form-item label="单位">
					<el-input v-model="ruleForm.company" placeholder="请输入您的单位"></el-input>
				</el-form-item>
				<el-form-item label="内容">
					<el-input type="textarea" v-model="ruleForm.content" placeholder="请输入您的内容"></el-input>
				</el-form-item>
				<el-form-item>
					<div class="sub" @click="onSubmit">提交</div>
				</el-form-item>
			</el-form>
		</div>
		
		<!-- 登录 -->
		<div id="ejy_huiyuan_baoming">
			<div class="theme-popover">
				<div class="theme-poptit">
					<a href="javascript:;" title="关闭" class="close">×</a>
					☺竞买人会员登录
				</div>
				<div class="theme-popbod dform">
					<form class="theme-signin" name="loginform" action="" method="post">
					<p><strong>会员：</strong><input class="ipt" type="text" name="username" value="" size="20"/></p>
					<p><strong>密码：</strong><input class="ipt" type="password" name="password" value="" size="20"/></p>
					<p><strong>&nbsp;&nbsp;&nbsp;</strong><input 
					class="ejy_huiyuan_baoming_btn ejy_huiyuan_dl" type="button" value=" 登 录 "/></p>
					</form>
					<font id="error" style="font-size: 12px;color: red;"></font>
				</div>
			</div>
			<div class="theme-popover-mask"></div>
		</div>
	</div>
</template>

<script>
	import $ from "jquery"
	import util from "@/assets/script/util.js"
	import moment from "moment"
	import qs from "qs"
	export default {
		data() {
			return {
				tapIndex: 0,
				scolft: 0,
				delObj:{},
				
				count: '', //倒计时
				seconds: 0, // 10天的秒数
				
				ruleForm: {
					title: '永登县城关镇和平街住宅转让推介公告',
					name: '',
					mobile: '',
					email: '',
					company: '',
					content: ''
				}
			}
		},
		
		computed:{
			timedd() {
				return (val)=>{
					return moment(val*1000).format("YYYY-MM-DD HH:mm:ss")
				}
			},
			imgSrc() {
				return val=>{
					if(val=="http://www.gsotc.com.cn/" || val=="") {
						return "http://www.gsotc.com.cn/uploads/images/20220804/07f69f66f6b15f7a8545756eb59f37ff.jpg"
					}else {
						return val
					}
				}
			},
			// 时间是否过期
			timeFlag() {
				return val=>{
					if(val!=null || val!="") {
						let date = new Date()
						let endDate = new Date(val.replace(/-/g,"/"))
						if(endDate>date) {
							return true
						}else {
							return false
						}
					}
					
				}
			}
		},
		created() {
			let id = this.$route.query.id
			this.getDetail(id)
		},
		mounted() {
			
			this.Time() //调用定时器
			
			$(document).ready(()=>{
				setTimeout(()=>{
					var ejyWindow = {
					    myWindow: "",
					    openLoading: function() {
					        this.myWindow = window.open('http://localhost:10019/js/loading.html');/**/
					    },
					    basePath: function() {
					        var location = (window.location + '').split('/');
					        return location[0]+'//'+location[2]+'/'+location[3];
					    },
					    openNewWin: function(url) {
					        if(this.myWindow) {
					            this.myWindow.location.href = url;
					        }
					    },
					    close: function() {
					        if(this.myWindow) {
					            this.myWindow.opener = null;
					            this.myWindow.open('', '_self');
					            this.myWindow.close();
					        }
					    },
					};
					
					var ejy_login_url ="https://www.ejy365.com/ejyhy/DanDianLogin/Pages/login.ashx";
						var ejy_baoming_url ="https://www.ejy365.com/ejyhy/DanDianLogin/Pages/Redirect.aspx?Projectno=";//产权项目报名接口
						//var ejy_baoming_url ="http://www.ejy365.com/ejyhy/DanDianLogin/Pages/Redirect.aspx?BiaoDuanGuid=";//采购项目报名接口
					
						//登录
						$(".ejy_huiyuan_dl").click(function(){
							var u =$("#ejy_huiyuan_baoming input[name=username]").val();
							var p =$("#ejy_huiyuan_baoming input[name=password]").val();
							//alert(u +"," +p);
							_loginOrBaoMing($(this) ,true ,u ,p);
						});
						
						//报名
						$(".ejy_huiyuan_bm").click(function(){
							console.log("pppppppppppp");
							_loginOrBaoMing($(this) ,false);
						});
						
						
						function _showDialog(data ,isLogin){
							if(!isLogin){
								data ="请先登录";
							}
							$("#ejy_huiyuan_baoming #error").show();
							$('#ejy_huiyuan_baoming .theme-popover-mask').fadeIn(100);
							$('#ejy_huiyuan_baoming .theme-popover').slideDown(200);
							//alert(data);
							$("#ejy_huiyuan_baoming #error").html("提示：" +data);
							$("#ejy_huiyuan_baoming #error").fadeOut(3000);
						}
						
						function _loginOrBaoMing($clickObj ,isLogin ,u ,p){
							var url;
							var projectNo =$clickObj.attr("id");
							
							if(!isLogin){//报名的时候
								if(projectNo ==null || projectNo ==""){
									alert("调用接口失败：请检查是否对按钮设置项目编号的属性id");
									return;
								}
								url =ejy_baoming_url + projectNo;
							}
							
							var params ={"u" :"" ,"p" :""};
							if(isLogin){
								params.u =u;
								params.p =p;
							}
							ejyWindow.openLoading();
							$.ajax({
							    type:"post",
								url: ejy_login_url,
								dataType: "jsonp",
								jsonp:"callback",
								data :params,
								jsonpCallback:"successCallback",
								xhrFields:{ 
									withCredentials:true 
								},
							    success:function(data){
									//TODO...
									//alert(json);
									var login =data ==null ?false :data =="success";
									if(login){
										if(isLogin){
											_ejyLgoinHideDialog();
											//if(confirm("登录成功，确定要跳转到报名页面？"))
											//window.open(url);
											//$("#ejy_huiyuan_baoming input[name=username]").val("");
											$("#ejy_huiyuan_baoming input[name=password]").val("");//为了安全：清空密码
											//var $hide_login =$("#ejy_huiyuan_baoming #ejy_huiyuan_baoming_loginHide");
											//$hide_login.attr("href" ,ejy_login_url +"?u=" +u +"&p=" +p);
											//$hide_login.find("i").click();
											//window.open(ejy_login_url +"?u=" +u +"&p=" +p,"_blank");
											//window.close();
											var obj_window = ejyWindow.openNewWin(ejy_login_url +"?u=" +u +"&p=" +p, '_blank');    
										//	obj_window.opener = ejyWindow.myWindow;    
										//	obj_window.focus();
										//	obj_window.close();  
										      ejyWindow.close(); 
											alert("登录成功");
											
										}else{
											//alert("isLogin="+isLogin+",准备请求：" +url);
											ejyWindow.openNewWin(url)
										//	window.open(url);
											console.log(url);
										}
									}else{
										_showDialog(data ,isLogin);
										ejyWindow.close();
									}
							     },error:function(XMLHttpRequest, textStatus, errorThrown) {
							     	//alert(XMLHttpRequest.status +"," +XMLHttpRequest.readyState +"," +textStatus);
								   	alert("登录异常，检查网络");
							     }
							});
							
							
						}
						
						function _ejyLgoinHideDialog(){
							$("#ejy_huiyuan_baoming .theme-popover-mask").fadeOut(100);
							$("#ejy_huiyuan_baoming .theme-popover").slideUp(200);
						}
					
						$(".theme-poptit .close").click(function(){
							_ejyLgoinHideDialog();
						});
				},1000)
					
				
			})
			
			
			
		},
		methods: {
			// 天 时 分 秒 格式化函数
			countDown() {
				
				let d = parseInt(this.seconds / (24 * 60 * 60))
				d = d < 10 ? "0" + d : d
				let h = parseInt(this.seconds / (60 * 60) % 24);
				h = h < 10 ? "0" + h : h
				let m = parseInt(this.seconds / 60 % 60);
				m = m < 10 ? "0" + m : m
				let s = parseInt(this.seconds % 60);
				s = s < 10 ? "0" + s : s
				this.count = d + '天' + h + '时' + m + '分' + s + '秒'
			},
			//定时器没过1秒参数减1
			Time() {
				setInterval(() => {
					this.seconds -= 1
					this.countDown()
				}, 1000)
			},
			
			onSubmit() {
				this.OnlineMessage()
			},
			// 在线留言
			OnlineMessage() {
				let url = this.$api.trading.OnlineMessage
				this.$axios.post(
					url,
					qs.stringify(this.ruleForm)
				).then(res => {
					if (res.code) {
						this.$message({
						    message: '提交成功',
						    type: 'success'
						});
						this.ruleForm = {}
						
					}else {
						this.$message({
						    message: res.msg,
						    type: 'error'
						});
					}
				})
			},
			// 点击标签栏
			clkTap(index) {
				this.tapIndex = index
			},
			// 点击左右滚动
			clkScoLft(index) {
				switch (index) {
					case 1:
						if(this.scolft-100<0) {
							this.scolft = 0
						}else {
							this.scolft -= 100
						}
						$("#lfImg").scrollLeft(-10);
						break;
					case 2:
						this.scolft += 100
						$("#lfImg").scrollLeft(this.scolft);
						break;
				}
			},
			// 查询详情
			getDetail(id) {
				let url = this.$api.index.noticeDetail
				this.$axios.get(url,{params:{id}}).then(res=>{
					if(res.code) {
						res.data.content = util.setContent(res.data.content)
						this.delObj = res.data
						
						this.ruleForm.title = res.data.title
						let endTime = res.data.enddate
						this.planTime(endTime)
					}
				})
			},
			
			// 计算时间差
			planTime(endTimes) {
				let date = new Date()
				let endTime = endTimes
				endTime = endTime.replace(/-/g,"/");
				let date2 = new Date(endTime)
				this.seconds = Math.round((date2.getTime()-date.getTime())/1000)
			}
		}
	}
</script>

<style lang="less" scoped>
	/deep/ .not-tap::after {
		height: 1px;
	}
	
	.tip {
		font-size: 22px;
		font-weight: 500;
		color: var(--themeColor);
		margin-top: 84px;
		padding-bottom: 20px;
		border-bottom: 0.5px solid rgba(235, 236, 239, 1);
	}
	.from {
		margin-top: 40px;
	}
	
	.sub {
		width: 114px;
		height: 48px;
		background-color: var(--themeColor);
		color: white;
		text-align: center;
		line-height: 48px;
		cursor: pointer;
	}

	.noticdd {
		display: flex;

		.not-left {
			width: 50%;

			.not-lft-img {
				border: 1px solid rgba(245, 245, 245, 1);
				box-sizing: border-box;
				height: 400px;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.not-arr {
				display: flex;
				height: 80px;
				justify-content: space-between;
				margin-top: 10px;
				width: 100%;

				.not-arr-item {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 50px;
					border: 1px solid rgba(245, 245, 245, 1);
					cursor: pointer;
					flex-shrink: 0;

					i {
						font-size: 20px;
					}

					&:hover {
						background-color: var(--themeColor);

						i {
							color: #fff;
						}
					}
				}

				.img {
					flex-grow: 1;
					margin: 0 10px;
					display: flex;
					overflow-x: hidden;
					box-sizing: border-box;

					img {
						height: 78px;
						width: 100px;
						object-fit: cover;
						flex-shrink: 0;
						margin-right: 10px;
						border: 1px solid var(--themeColor);

						&:last-child {
							margin-right: 0;
						}
					}
				}
			}
		}

		.not-right {
			width: 50%;

			.not-right-top {
				height: 400px;
				border: 1px solid rgba(245, 245, 245, 1);
				border-left: none;
				padding: 50px 30px;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.title {
					font-size: 18px;
				}

				.no {
					font-size: 14px;
					margin-top: 14px;
				}

				.baom {
					display: flex;
					margin-top: 25px;

					.bm {
						width: 60px;
						height: 60px;
						font-size: 18px;
						color: #fff;
						background-color: var(--themeColor);
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
					}
					.jzbm {
						background-color: #d8d8d8;
					}

					.time {
						border: 1px dashed var(--themeColor);
						height: 60px;
						font-size: 14px;
						display: flex;
						align-items: center;
						padding-left: 20px;
						color: var(--themeColor);
						width: 70%;
						box-sizing: border-box;
					}
					.jztime {
						border: 1px dashed #dadada;
						color: #dadada;
					}

				}

				.gpjg {
					font-size: 14px;
					margin-top: 25px;

					.price {
						font-size: 18px;
						color: red;
					}
				}

				.city {
					font-size: 14px;
					margin-top: 10px;
				}

				.btnbm {
					width: 122px;
					height: 42px;
					margin-top: 30px;
					background-color: var(--themeColor);
					// background-color: red;
					color: #FFFFFF;
					font-size: 18px;
					line-height: 42px;
					text-align: center;
					border-radius: 0px;
					cursor: pointer;
				}
				.jzbm {
					background-color: #dadada;
					cursor: no-drop;
				}
			}

			.not-right-bom {
				margin-top: 10px;
				font-size: 14px;
				color: #666;
				box-sizing: border-box;
				padding-left: 20px;
				padding-top: 5px;

				.end-time {
					margin-top: 3px;
				}
			}
		}
	}

	.lcimg {
		margin: 30px 0;

		img {
			width: 100%;
		}
	}
</style>
